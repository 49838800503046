// src/utils/gloriaFoodApi.js
import axios from 'axios';
import SampleData from '../utils/SampeData.json'

const key = process.env.REACT_APP_GLORIA_FOODS_API_KEY;
const API_ENDPOINT = process.env.REACT_APP_GLORIA_FOODS_MENU_API_ENDPOINT;

const MAX_RETRY_ATTEMPTS = 3;

const fetchMenu = async () => {
  try {
    let menuData = null;
    let retryCount = 0;

    while (menuData === null && retryCount < MAX_RETRY_ATTEMPTS) {
      const response = await fetch(API_ENDPOINT, {
        method: 'GET',
        headers: {
          'Authorization': key,
        },
        mode: 'cors'
      });

      if (response.ok) {
        menuData = await response.json();
        // console.log('Menu Data:', menuData);
        // console.log("Amount of extra calls: " + retryCount)
      } else {
        console.error('Error:', response.status, response.statusText);
        retryCount++;
        // Optionally add a delay before retrying
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
      }
    }

    if (menuData === null) {
      console.error('Reached maximum retry attempts. Unable to fetch menu data.');
    }

    return menuData;
    // return SampleData;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export default fetchMenu;

