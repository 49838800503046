// src/Footer.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components'

const LocationText = styled.h1`
    font-family: 'Anton', sans-serif;
    color: black;
    font-size: 1.5rem;
    text-transform: uppercase;
`;

const ItemCard = styled(Card)`
    height: 100%
    border: none;
`

const Footer = () => {
  return (
    <Container fluid className="text-center mt-4">
      <Row className="justify-content-center" style={{padding: '4rem 0rem'}}>
        <Col md={3}>
          <p style={{ fontFamily: 'Anton, sans-serif', color: 'black', fontSize: '1.5rem' }}>Location</p>
          <LocationText className='text-muted'>4709 Tuckaseegee Rd #8</LocationText>
          <LocationText className='text-muted'>Charlotte, NC 28208</LocationText>
        </Col>
        <Col md={3}>
          <p style={{ fontFamily: 'Anton, sans-serif', color: 'black', fontSize: '1.5rem' }}>Hours</p>
          <LocationText className='text-muted'>Monday-Sunday 9AM - 9PM</LocationText>
        </Col>
        <Col md={3}>
          <p style={{ fontFamily: 'Anton, sans-serif', color: 'black', fontSize: '1.5rem' }}>Telephone</p>
          <LocationText className='text-muted'>704-393-1293</LocationText>
        </Col>
      </Row>
      <p className='text-muted' style={{ fontSize: '1.2rem' }}> &copy; {(new Date().getFullYear())} by La Acapulqueña</p>
      <p className="text-muted" style={{ fontSize: "1rem" }}>created by &copy; Crunchy Dev</p>
    </Container>
  );
};

export default Footer;
