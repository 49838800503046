// src/Menu.js
import { useMenuData } from '../utils/gfContextApi';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import torta from '../pictures/Torta.png'
import sopes from '../pictures/UnSope.png'
import tacos from '../pictures/Tacos.png'
import logo from '../pictures/AcapulquenaLogo.png'
import quesadilla from '../pictures/TacosDoradosConQueso.png'
import flautas from '../pictures/Flautas2.png'
import carneAsada from '../pictures/CarneAsada.png'
import tamales from '../pictures/Tamales.png'
import quesadillaGrande from '../pictures/Quesadilla.png'
import burrito from '../pictures/Burrito.png'
import aguasFrescas from '../pictures/AguasFrescas.png'
import styled from 'styled-components'

const CategoryText = styled.h1`
    font-family: 'Anton', sans-serif;
    color: #ff6961;
`;

const ItemText = styled.h1`
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    color: #44464A;
    text-transform: uppercase;
`;

const SizeText = styled.h1`
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #44464A;
`;

const ItemCard = styled(Card)`
    height: 100%
`


const EnglishMenu = () => {

  const menuData = useMenuData();

  const imageHashMap = {
    'Tacos': tacos,
    'Tortas': torta,
    'Sopes': sopes,
    'Burrito Regular': burrito,
    'Burrito Grande': burrito,
    'Quesadilla': quesadilla,
    'Quesadilla Grande': quesadillaGrande,
    'Tamales': tamales,
    'Aguas Frescas': aguasFrescas,
    '#1 Carne Asada w/ Rice & Beans': carneAsada,
    '#2 Three Tacos': tacos,
    '#3 Five Chicken Taquitos Platter': flautas,
    '#4 Three Fried Quesadillas': quesadilla
  };

  // Define the index range for which you want to render items
  const endSpecialItems = 1; // Example starting index
  const endRegularItems = 9; // Example ending index
  const endExtraItems = 12; // Example ending index

  // Slice the menuData array to include only the desired categories
  const filteredSpecialCategories = menuData.slice(0, endSpecialItems);
  const filteredRegularCategories = menuData.slice(1, endRegularItems + 1);
  const filteredExtraCategories = menuData.slice(10, endExtraItems + 1);

  return (
    <Container className="mt-4" style={{ backgroundColor: '#F8F0E3', borderRadius: '2rem' }}>
      <ItemText className="text-center display-4" style={{ marginBottom: '3vh', paddingTop: '1rem' }}>***Products with an asterik beside them will have an additional surcharge.***</ItemText>
      <Row className="justify-content-left text-center">
        {filteredSpecialCategories.map((category) => (
          <Col key={category.id} className="mb-4">
            <ItemCard>
              <Card.Body>
                <CategoryText>
                  <Card.Title style={{ fontSize: '2rem' }}>
                    {category.description}
                  </Card.Title>
                  <p></p>
                </CategoryText>

                <Row className="justify-content-left text-center">
                {category.items.map((item) => (
                  
                    <Col md={6} lg={6} className="mb-4">
                      <div key={item.id}>
                        <Card.Text>
                          <ItemText>{item.description}</ItemText>
                          <CategoryText>${item.price}</CategoryText>
                          {imageHashMap[item.description] && (<Image src={imageHashMap[item.description]} alt={item.description} fluid />)}
                        </Card.Text>

                        {item.sizes.map((size) =>
                          <div key={size.id}>
                            <Card.Text>
                              <SizeText>{size.name} </SizeText>
                            </Card.Text>
                          </div>
                        )}
                        <p></p>
                      </div>
                    </Col>
                  
                ))}
                </Row>
              </Card.Body>
            </ItemCard>
          </Col>
        ))}

      </Row>

      <Row className="justify-content-left text-center">

        {filteredRegularCategories.map((category) => (
          <Col key={category.id} md={6} lg={4} className="mb-4">
            <ItemCard>
              <Card.Body>
                <CategoryText>
                  <Card.Title style={{ fontSize: '2rem' }}>
                    {category.description} ${category.items[0].price}
                    {imageHashMap[category.name] && (<Image src={imageHashMap[category.name]} alt={category.name} fluid />)}
                  </Card.Title>
                </CategoryText>
                {category.items.map((item) => (
                  <div key={item.id}>
                    <Card.Text>
                      <ItemText>{item.description}</ItemText>
                    </Card.Text>
                    {/* Additional fields for the item */}
                  </div>
                ))}
              </Card.Body>
            </ItemCard>
          </Col>
        ))}

        {filteredExtraCategories.map((category) => (
          <Col key={category.id} md={6} lg={4} className="mb-4">
            <ItemCard>
              <Card.Body>
                <CategoryText>
                  <Card.Title style={{ fontSize: '2rem' }}>
                    {category.name}
                  </Card.Title>
                </CategoryText>
                {category.items.map((item) => (
                  <div key={item.id}>
                    <Card.Text>
                      <ItemText>{item.name} ${item.price}</ItemText>
                    </Card.Text>

                    {item.sizes.map((size) =>
                      <div key={size.id}>
                        <Card.Text>
                          <SizeText>{size.name}</SizeText>
                        </Card.Text>
                      </div>
                    )}
                    <p></p>
                  </div>
                ))}
              </Card.Body>
            </ItemCard>
          </Col>
        ))}

      </Row>
    </Container>
  );
};

export default EnglishMenu;