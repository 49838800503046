// src/Header.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';
import restaurantLogo from '../pictures/AcapulquenaLogo.png'

const HeaderText = styled.h1`
  font-family: 'Anton', sans-serif;
  color: #ff6961;
  font-size: 3rem;
`;

const HeaderTextSmall = styled.h1`
  font-family: 'Anton', sans-serif;
  color: #ff6961;
  font-size: 1.5rem;
  padding-right: 0.2rem;
`;

const OrderButton = styled.a`
  font-family: 'Anton', sans-serif;
  font-size: 2rem;
  background-color: #ff6961;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  text-decoration: none;
  margin-left: auto; /* Push the button to the right side */

  &: hover{

    background: rgb(54, 194, 12);
    transition: all 0.5s ease-in-out;
    color: #fff;
    opacity: 0.9;
}

@media screen and (max-width: 820px){
  transition: 0.8s all ease;
  visibility: hidden;
}
`;

const RestaurantLogo = styled.img`
  width: 150px; 
  height: 150px; 
  margin-top: -3.6rem; 
  display: flex; 
  position: fixed;

  @media screen and (max-width: 820px){
    width: 130px;
    height: 130px;
  }
`
const CustomNavbar = styled.nav`
backgroundColor: white; 
paddingBottom: 1rem; 
borderBottomLeftRadius: 1rem; 
borderBottomRightRadius: 1rem; 
marginLeft: 9rem;
marginRight: 9rem; 
border: solid #44464A;

@media screen and (max-width: 820px){
  marginLeft: 0rem;
  marginRight: 0rem;
}

`

const Header = () => {
  return (
    <Navbar fixed="top" style={{backgroundColor: 'white', boxShadow: '0px 0px 11px #44464A'}}>
      <Container style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{display: 'none'}}>La Acapulqueña</h1>
        <h2 style={{display: 'none'}}>The best taqueria in Charlotte.</h2>
        <div style={{display: 'flex'}}>
        <HeaderTextSmall>LA</HeaderTextSmall> <HeaderText>ACAPULQUEÑA</HeaderText>
        </div>
        <div>
        <RestaurantLogo
            src={restaurantLogo}
            alt="Restaurant Logo"
          />
        </div>
        <div></div>
        <div>
        {/* <OrderButton href="https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=96286239-45ca-48c5-bab2-8574cdf1d4bc">Ordernar/Order</OrderButton> */}
        <OrderButton class="orderButton" href="tel: +17043931293">Ordena/Order</OrderButton>
        </div>
        {/* Place button here */}
      </Container>
    </Navbar>
  );
};

export default Header;

