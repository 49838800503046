import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jarritos from '../pictures/Jarritos.jpeg'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

const ModalHeading = styled.h1`
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #28d767;
    font-size: 1.5rem;
    text-transform: uppercase;
    background-color:  transparent;
    text-shadow: 3px 3px 1px #44464A;
`

const HighlightText = styled.span`
    color:  red;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0rem 0.5rem;
    text-align: center;
`

const ModalText = styled.div`  
    margin-top: 1rem;
    color:  #44464A;
    font-weight: 500;
    font-size: 1rem;
    padding: 0rem 0.5rem;
    text-align: center;
`

function PromotionPopup() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className='justify-content-center'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <ModalHeading>¡Promoción especial! 🎉 🌮</ModalHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalText style={{textAlign: 'center', fontSize: '1.3rem'}}>¡Muchas Gracias!</ModalText>
                    <ModalText>
                        De parte de LA ACAPULQUEÑA le damos gracias por ser parte de nuestro nuevo comienzo en el espacio digital.
                        Estamos encantados de servirle y
                        esperamos que disfrute de nuestra auténtica
                        cocina mexicana.
                    </ModalText>
                    <ModalText style={{border: '2px solid #28d767', borderRadius: '1rem', fontWeight: '700'}}>
                    Estaremos regalando un Jarrito a las primeras 10 órdenes en línea cada día este fin de semana del 8 de marzo al 10 de marzo con la compra de $15 o más usando una tarjeta de débito o crédito.
                    </ModalText>
                    <br/>
                    <img src={jarritos} alt="Description" style={{width: '100%', borderRadius: '1rem'}}/>
                    <div style={{padding: '0 0rem'}}>
                    <span style={{fontSize: '0.4rem'}}>Jarrito flavor received may vary. While supplies last. Online orders only with debit or credit payment. Orders under $15 are not eligible.</span>
                    <span style={{fontSize: '0.4rem'}}>Sabor del jarrito recibido puede variar. Mientras duren las reservas. Pedidos en línea únicamente con pago de débito o crédito. Los pedidos menos de $15 no son elegibles.</span>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default PromotionPopup;

