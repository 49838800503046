// src/App.js
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import LanguagesTabs from './components/LanguagesTabs'
import { MenuDataProvider } from './utils/gfContextApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './components/Menu';
import PromotionPopup from './components/PromotionPopup';

function App() {
  
  return (
    <MenuDataProvider>
      <Header />
      {/* <PromotionPopup /> */}
      {/* <LanguagesTabs /> */}
      <Menu />
      <Footer />
    </MenuDataProvider>
  );
}

export default App;
