// src/Menu.js
import { useEffect, useState } from 'react';
import { useMenuData } from '../utils/gfContextApi';
import { Container, Row, Col, Card, Image, Spinner } from 'react-bootstrap';
import torta from '../pictures/Torta.png'
import sopes from '../pictures/UnSope.png'
import tacos from '../pictures/Tacos.png'
import logo from '../pictures/AcapulquenaLogo.png'
import quesadilla from '../pictures/TacosDoradosConQueso.png'
import flautas from '../pictures/Flautas2.png'
import carneAsada from '../pictures/CarneAsada.png'
import tamales from '../pictures/Tamales.png'
import quesadillaGrande from '../pictures/Quesadilla.png'
import burrito from '../pictures/Burrito.png'
import aguasFrescas from '../pictures/AguasFrescas.png'
import styled from 'styled-components'

const CategoryText = styled.h5`
    font-family: 'Anton', sans-serif;
    color: #ff6961;
`;

const ItemText = styled.h1`
    font-family: Source Code Pro, monospace;
    font-weight: 900;
    font-size: 1rem;
    text-align: left;
    color: #44464A;
    text-transform: uppercase;
`;

const SizeText = styled.p`
    font-family: Source Code Pro, monospace;
    font-weight: 900;
    font-size: 1rem;
    color: #44464A;
    text-transform: uppercase;
`;

const ItemCard = styled(Card)`
    height: 100%
`


const Menu = () => {
  const [count, setCount] = useState(2);

  useEffect(() => {
    // Only set up the interval if count is greater than 0
    if (count > 0) {
      const timerId = setTimeout(() => {
        setCount(count - 1); // Decrease count by 1
      }, 1000); // Set timeout for 1 second

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timerId);
    }
  }, [count]);

  const menuData = useMenuData();

  const imageHashMap = {
    'Tacos': tacos,
    'Tortas': torta,
    'Sopes': sopes,
    'Burrito Mediano': burrito,
    'Burrito Grande': burrito,
    'Quesadilla Dorada': quesadilla,
    'Quesadilla Grande': quesadillaGrande,
    'Tamales': tamales,
    'Aguas Frescas': aguasFrescas,
    'Carne Asada con Arroz y Frijoles': carneAsada,
    'Tres Tacos': tacos,
    'Cinco Flautas de Pollo': flautas,
    'Tres Quesadillas Doradas': quesadilla
  };

  // Define the index range for which you want to render items
  const endSpecialItems = 1; // Example starting index
  const endRegularItems = 9; // Example ending index
  const endExtraItems = 12; // Example ending index

  // Slice the menuData array to include only the desired categories
  const filteredSpecialCategories = menuData.slice(0, endSpecialItems);
  const filteredRegularCategories = menuData.slice(1, endRegularItems + 1);
  const filteredExtraCategories = menuData.slice(10, endExtraItems + 1);



  return (
    <>
     {count > 0 ? (
      <div style={{marginTop: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    ):(
      <div style={{ marginTop: '10rem'}}>
      <Container className="mt-4">

        <Row className="justify-content-left text-center">
          {filteredSpecialCategories.map((category) => (
            <Col key={category.id} className="mb-4">
              <ItemCard className="border-0">
                <Card.Body>
                  <CategoryText>
                    <Card.Title style={{ fontSize: '5rem', color: '#44464A' }}>
                      {category.name}
                    </Card.Title>
                    <ItemText className="text-center display-4" style={{ marginBottom: '2vh', paddingTop: '1rem', fontSize: '.7rem' }}>Algunos productos tendrán un cargo extra</ItemText>
                    <p></p>
                  </CategoryText>

                  <Row className="justify-content-left text-center">
                    {category.items.map((item) => (

                      <Col md={6} lg={6} className="mb-4">
                        <div key={item.id}>
                          <Card.Text>
                            {imageHashMap[item.name] && (<Image src={imageHashMap[item.name]} alt={item.name} fluid />)}
                            <ItemText style={{textAlign: 'center'}}>{item.name}</ItemText>
                            <CategoryText>${item.price}</CategoryText>
                          </Card.Text>

                          {item.sizes.map((size) =>
                            <div key={size.id}>
                              <Card.Text>
                                <SizeText>{size.name} </SizeText>
                              </Card.Text>
                            </div>
                          )}
                          <p></p>
                        </div>
                      </Col>

                    ))}
                  </Row>
                </Card.Body>
              </ItemCard>
            </Col>
          ))}
          <p style={{ textAlign: 'center', fontSize: '8px' }}>*Product presentation may vary. Not actual size.</p>
        </Row>

        <Row className="justify-content-left text-center">

          {filteredRegularCategories.map((category) => (
            <Col key={category.id} md={6} lg={4} className="mb-4">
              <ItemCard>
                <Card.Body>
                  <CategoryText>
                    <Card.Title style={{ fontSize: '4rem' }}>
                      {category.name} <ItemText style={{textAlign: 'center'}}>${category.items[0].price}</ItemText>
                      {imageHashMap[category.name] && (<Image src={imageHashMap[category.name]} alt={category.name} fluid />)}
                    </Card.Title>
                  </CategoryText>
                  {category.groups.map((item) => (
                    item.name !== "Extras" && (
                      <div key={item.id}>
                        {item.options.map((option) => (
                          <Card.Text key={option.id}>
                            <ItemText>- {option.name} {option.price !== 0 &&("+ $" + option.price)}</ItemText>
                          </Card.Text>
                        ))}
                      </div>
                    )
                  ))}
                </Card.Body>
              </ItemCard>
            </Col>
          ))}

          {filteredExtraCategories.map((category) => (
            <Col key={category.id} md={6} lg={4} className="mb-4">
              <ItemCard>
                <Card.Body>
                  <CategoryText>
                    <Card.Title style={{ fontSize: '2rem' }}>
                      {category.name}
                    </Card.Title>
                  </CategoryText>
                  {category.items.map((item) => (
                    <div key={item.id}>
                      <Card.Text>
                        <ItemText>- {item.name} ${item.price}</ItemText>
                      </Card.Text>

                      {/* {item.sizes.map((size) =>
                        <div key={size.id}>
                          <Card.Text>
                            <SizeText>{size.name}</SizeText>
                          </Card.Text>
                        </div>
                      )} */}
                      <p></p>
                    </div>
                  ))}
                </Card.Body>
              </ItemCard>
            </Col>
          ))}

        </Row>
      </Container>
    </div>
    )}
    </>
  );
};

export default Menu;

