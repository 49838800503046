import { createContext, useContext, useState, useEffect } from 'react';
import fetchMenu from '../utils/gloriaFoodApi';

const MenuDataContext = createContext();

export const useMenuData = () => {
  return useContext(MenuDataContext);
};

export const MenuDataProvider = ({ children }) => {
  const [menuData, setMenuData] = useState([]);

  const fetchData = async () => {
    try {
      const data = await fetchMenu();
      setMenuData(data.categories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MenuDataContext.Provider value={menuData}>
      {children}
    </MenuDataContext.Provider>
  );
};