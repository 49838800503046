import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import SpanishContent from '../components/Menu'; // Import your Spanish content component
import EnglishContent from '../components/EnglishMenu'; // Import your English content component
import styled from 'styled-components'

const CategoryText = styled.h1`
    font-family: 'Anton', sans-serif;
    
    font-size: 2rem;
`;

const CustomNav = styled(Nav)`
  .nav-link {
     /* Set the background color for inactive tabs */
    color: #44464A; /* Set the text color for inactive tabs */
    opacity: .5;
  }

  .nav-link.active {
    /* Set the background color for the active tab */
    color: #44464A; /* Set the text color for the active tab */
    opacity: 1;
  }
`;

function LanguagesTabs() {
    return (
        <div style={{ marginTop: '6rem' }}>
            <Tab.Container defaultActiveKey="/spanish">
                <CustomNav fill variant="underline">
                    <Nav.Item>
                        <Nav.Link eventKey="/spanish">
                            <CategoryText>Menú Completa</CategoryText>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/english">
                            <CategoryText>Full Menu</CategoryText>
                        </Nav.Link>
                    </Nav.Item>
                </CustomNav>
                <div className="tab-content">
                    <Tab.Content>
                        <Tab.Pane eventKey="/spanish">
                            <SpanishContent />
                        </Tab.Pane>
                        <Tab.Pane eventKey="/english">
                            <EnglishContent />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}

export default LanguagesTabs;
